import './bootstrap';

try {
    window.AOS = require('aos');
    window.Swal = require('sweetalert2');
    require('slick-carousel');
    require('lightgallery.js');
} catch (e) {
    console.log(e);
}
